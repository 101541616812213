import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section, BreadCrumb, MarkdownContent, MarkdownContentWrapper, MarkdownContentWrapperInner, MarkdownContentTitle, ComingSoon } from '../components/Section'
import { Link } from "gatsby"
import styled from 'styled-components'
import BgImage from '../images/brand-list.jpg'
import PrimaryLinkButton from "../components/Button/PrimaryLinkButton";
import { StaticImage } from "gatsby-plugin-image"
import BreakpointDown from "../components/Media/BreakpointDown";


const SectionBgAfter = styled(Section)`
 &:after{
   right:0;
   left:0;
   background-size:cover;
 }
 .container{
   z-index:1;
 }
`
const PageTitle = styled.h1`
 margin-bottom:0;
 text-transform: capitalize;
 text-align:center;
`
// const BrandLogo = styled.div`
//  position:relative;
//  margin-left:auto;
//  margin-right:auto;
//  max-width:200px;
//  margin-top:10px;
// `
const PageTitleWrapper = styled.div`
text-align:center;
font-size:16px;
img{ margin-top:10px;}
p{ margin-bottom:0;}
`
const SectionTitle = styled.h2`
 margin-bottom:0;
`
const BrandWrapper = styled.div`
  &+&{
    margin-top:100px;
    ${BreakpointDown.md`
     margin-top:40px;
    `}
  }
`
// blog Card 

const BrandRow = styled.div`
 display:flex;
 flex-wrap:wrap;
 margin-left:-20px;
 margin-right:-20px;
 ${BreakpointDown.lg`
  margin-left:-15px;
  margin-right:-15px;
 `}

`
const BrandColumn = styled.div`
 flex:0 0 100%;
 width:100%;
 padding-left:20px;
 padding-right:20px;
 margin-top:40px;
 ${BreakpointDown.lg`
   padding-left:15px;
 padding-right:15px;
 `}
 ${BreakpointDown.md`
  margin-top:30px;
 `}
`

const Card = styled.div`
  padding: 70px 62px;
  background:#fff;
  display:flex;
  height:100%;
  align-items:center;
  box-shadow: 0px 20px 45px rgb(0, 0, 0, 0.20);
  @media(max-width:1440px){
    padding: 15px;
  }
  ${BreakpointDown.md`
   text-align:center;
   flex-wrap:wrap; 
 `}
  .btn{
    margin-top:0px;
  }
`
const CardTitle = styled(Link)`
 margin-bottom:6px;
`
const CardImg = styled.div`
  position: relative;
  max-width:200px;
  width:100%;
  margin-top:26px;
  ${BreakpointDown.md`
     max-width:200px;
     margin-top:0px;
     margin-left:auto;
     margin-right:auto; 
  `}
  .gatsby-image-wrapper{ 
    width:100%;
  }
`
const BrnadDesciption = styled.div`
  display:flex;
  flex-direction:column;
  padding:30px 30px 30px 130px;
${BreakpointDown.lg`
   padding:15px;
`}
${BreakpointDown.md`
   padding:0;
   margin-top:15px;
`}
p{ font-size:16px;}
`
const TraingleShape = styled.div`
  width: 0;
  height: 0;
  display: block;
  border-top: 20px transparent solid;
  border-right: 20px transparent solid;
  border-left: 20px transparent solid;
  border-bottom: 20px #fff solid;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
  margin-left: auto;
  margin-right: auto;
  z-index: 3; 
`
const isBrand = false
function BrandListPage() {
  return (
    <Layout pageName="brand-list">
      <SEO title="UBIQUITI" />
      <SectionBgAfter pt="156px" pb="100px" xpt="90px" xpb="60px" mpt="60px" mpb="40px" bgAfter={`url(${BgImage})`}>
        <TraingleShape />
        <div className="container">
          <BreadCrumb>
            <Link to="/">Home</Link> <Link to="/#">UBIQUITI</Link>
          </BreadCrumb>
          <PageTitle>UBIQUITI</PageTitle>
          {/* <BrandLogo>
              <StaticImage
                src="../images/brand-1.png"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Letast Article"
                placeholder="blurred"
              />
            </BrandLogo> */}
          {/* <p>Ubiquiti delivers the most compelling feature-rich Enterprise-class IP phone system available. Our award-winning platform allows businesses to easily deploy a complete voice and Unified Communications solution to connect all of their offices and teams together through a highly survivable distributed network. Whether a company has one office or multiple locations, their employees can work together as a single integrated team, boosting productivity and the quality of service provided to their customers.</p> */}
        </div>
      </SectionBgAfter>
      <Section pt="60px" mpt="40px" mpb="40px" bgColor="#F8F8F8" pb="100px" xpb="60px">
        <div className="container">

          <BrandWrapper>
            <PageTitleWrapper>
              <SectionTitle className="h1">Products</SectionTitle>
              {/* <p>Lorem Ipsum is simply dummy text of the printing and type setting industry lorem Ipsum been industry’s standard.</p> */}
              <ComingSoon textAlign="center">Coming Soon..</ComingSoon>
            </PageTitleWrapper>
            {isBrand && <BrandRow>
              {['ZIP 43GExceptional Value in a Feature Rich IP Phone', 'ZIP 45G Advanced Gigabit IP Business Phone', 'ZIP 47G Advanced Color Gigabit IP Business Phone', 'ZIP 49G Advanced Color Gigabit IP Business Phone'].map((item, index) => (
                <BrandColumn key={index}>
                  <Card>
                    <CardImg>
                      <StaticImage
                        src="../images/phone-reciever.png"
                        quality={100}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="Letast Article"
                        placeholder="blurred"
                      />
                    </CardImg>
                    <BrnadDesciption>
                      <CardTitle to="/brand-detail" className="h5">{item}</CardTitle>
                      <p>The ZIP 47G combines a hi-resolution color display and dual Gigabit Ethernet ports in a feature rich IP phone ideal for busy executives and heavy phone users. Functions and contacts may be accessed quickly via 27 programmable soft keys.</p>
                      <Link to="/brand-detail"><PrimaryLinkButton text="View Full Detail" /></Link>
                    </BrnadDesciption>
                  </Card>
                </BrandColumn>
              ))}
            </BrandRow>}
          </BrandWrapper>
          {isBrand && <BrandWrapper>
            <PageTitleWrapper>
              <SectionTitle to="/brand-detail">IP Phone Systems</SectionTitle>
              {/* <p>Lorem Ipsum is simply dummy text of the printing and type setting industry lorem Ipsum been industry’s standard.</p> */}
              <ComingSoon textAlign="center">Coming Soon..</ComingSoon>
            </PageTitleWrapper>
            <BrandRow>
              {['ZIP 43GExceptional Value in a Feature Rich IP Phone', 'ZIP 45G Advanced Gigabit IP Business Phone', 'ZIP 47G Advanced Color Gigabit IP Business Phone', 'ZIP 49G Advanced Color Gigabit IP Business Phone'].map((item, index) => (
                <BrandColumn>
                  <Card>
                    <CardImg>
                      <StaticImage
                        src="../images/phone-reciever.png"
                        quality={100}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="Letast Article"
                        placeholder="blurred"
                      />
                    </CardImg>
                    <BrnadDesciption>
                      <CardTitle to="/brand-detail" className="h5">{item}</CardTitle>
                      <p>The ZIP 47G combines a hi-resolution color display and dual Gigabit Ethernet ports in a feature rich IP phone ideal for busy executives and heavy phone users. Functions and contacts may be accessed quickly via 27 programmable soft keys.</p>
                      <Link to="/brand-detail"><PrimaryLinkButton text="View Full Detail" /></Link>
                    </BrnadDesciption>
                  </Card>
                </BrandColumn>
              ))}
            </BrandRow>
          </BrandWrapper>}
        </div>
      </Section>
      <Section pt="100px" pb="100px" xpt="60px" xpb="60px" mpb="40px" mpt="40px">
        <div className="container">
          <MarkdownContentWrapper>
            <MarkdownContentWrapperInner>
              <MarkdownContentTitle>
                <h2 className="h1">Content Heading</h2>
              </MarkdownContentTitle>
              {isBrand && <> <MarkdownContent>
                <h3>Heading</h3>
                <p>Lorem Ipsum is simply dummy text of the printing and type setting industry lorem Ipsum has been industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged lorem Ipsum is simplydummy text of the printing and type setting industry lorem Ipsum has been industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.</p>
                <ul>
                  <li>Lorem Ipsum is simply dummy text and type setting industry.</li>
                  <li>Lorem Ipsum is simply dummy text of the printing and type setting industry.</li>
                  <li>Lorem Ipsum is simply the printing and type setting industry.</li>
                  <li>Lorem Ipsum is simply dummy text and type setting industry.</li>
                  <li>Lorem Ipsum is simply the printing and type setting industry.</li>
                </ul>
              </MarkdownContent>
                <MarkdownContent>
                  <h3>Heading</h3>
                  <p>Lorem Ipsum is simply dummy text of the printing and type setting industry lorem Ipsum has been industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged lorem Ipsum is simplydummy text of the printing and type setting industry lorem Ipsum has been industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.</p>

                  <p>Lorem Ipsum is simply dummy text of the printing and type setting industry lorem Ipsum has been industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged lorem Ipsum is simplydummy text of the printing and type setting industry lorem Ipsum has been industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.</p>
                </MarkdownContent>
                <MarkdownContent>
                  <h3>Heading</h3>
                  <p>Lorem Ipsum is simply dummy text of the printing and type setting industry lorem Ipsum has been industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged lorem Ipsum is simplydummy text of the printing and type setting industry lorem Ipsum has been industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.</p>
                </MarkdownContent>
                <MarkdownContent>
                  <h3>Heading</h3>
                  <p>Lorem Ipsum is simply dummy text of the printing and type setting industry lorem Ipsum has been industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged lorem Ipsum is simplydummy text of the printing and type setting industry lorem Ipsum has been industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.</p>
                  <ul>
                    <li>Lorem Ipsum is simply dummy text and type setting industry.</li>
                    <li>Lorem Ipsum is simply dummy text of the printing and type setting industry.</li>
                    <li>Lorem Ipsum is simply the printing and type setting industry.</li>
                    <li>Lorem Ipsum is simply dummy text and type setting industry.</li>
                    <li>Lorem Ipsum is simply the printing and type setting industry.</li>
                  </ul>

                  <p>Lorem Ipsum is simply dummy text of the printing and type setting industry lorem Ipsum has been industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged lorem Ipsum is simplydummy text of the printing and type setting industry lorem Ipsum has been industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.</p>
                </MarkdownContent></>}
              <ComingSoon textAlign="center">Coming Soon..</ComingSoon>
            </MarkdownContentWrapperInner>
          </MarkdownContentWrapper>
        </div>
      </Section>
    </Layout>
  )
}
export default BrandListPage